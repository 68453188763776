// import Swiper JS
import Swiper, { Autoplay, Navigation, EffectCoverflow, EffectFade, FreeMode, Thumbs } from 'swiper';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-coverflow';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';

let swiperBreakPoint = 768;
let _homepage = document.body.classList.contains('home');
let _prev_collection = document.body.classList.contains('page_previous_collection');
let _offwhite_collections = document.body.classList.contains('page_offwhite_collections');
let _bridal = document.body.classList.contains('page_bridal');
let _single_post = document.body.classList.contains('page_blog');
let _page_team  = document.body.classList.contains('page_team');
let _page_store  = document.body.classList.contains('page_store');
let _page_product  = document.body.classList.contains('page_product');

// ! SLIDERS
// * HOME
const __homepage__recommended = '.homepage__recommended';
const __homepage__section_six = '.homepage__section_six';
const __homepage__sub_recommended = '.homepage__sub-recommended';
const __home__shop_the_look = '.homepage__shop-the-look';
const __home__best_sellers = '.homepage__best-sellers';
const __home__special_offers = '.homepage__special-offers';
// * TEAM
const __team_arc = '.the-team__arc';
// * BRIDAL
const __bridal_b = '.bridal-b';
// * PREVIOUS COLLECTION
const __prev_collection_a = '.prev-collection-a';
const __prev_collection_d = '.prev-collection-d';
// * OFFWHITE COLLECTIONS
const __offwhite_collections = '.offwhite-collections__slider';
// * SINGLE POST
const __single_post = '.single-post-a';
// * STORE
const __store = '.store';

window.addEventListener('DOMContentLoaded', () => {

    if (_homepage) {
        if(document.querySelector(__homepage__recommended+'--slider')){
            homeRecommendedSlider(__homepage__recommended);
        }

        if(document.querySelector(__homepage__section_six+'--slider')){
            homeRecommendedSlider(__homepage__section_six);
        }
        
        if(document.querySelector(__homepage__sub_recommended+'--slider')){
            homeSubRecommendedSlider(__homepage__sub_recommended);
        }
        if(document.querySelector(__home__best_sellers+'--slider')){
            homeBestSellersSlider(__home__best_sellers);
        }
        if(document.querySelector(__home__shop_the_look+'--slider')){
            homeShopTheLookSlider(__home__shop_the_look);
        }
        if(document.querySelector(__home__special_offers+'--slider')){
            homeSpecialOffersSlider(__home__special_offers);
        }
    }

    if (_prev_collection) {        
        if(document.querySelector(__prev_collection_a+'--slider')){
            prevCollectionASlider(__prev_collection_a);
        }
        if(document.querySelector(__prev_collection_d+'--slider')){
            prevCollectionDSlider(__prev_collection_d);
        }
    }
    if (_offwhite_collections) {
        if(document.querySelector(__offwhite_collections+'--slider')){
            offwhiteCollectionsSlider(__offwhite_collections);
        }
    }

    if (_bridal) {
        if(document.querySelector(__bridal_b+'--slider')){
            bridalBSlider(__bridal_b);
        }
    }

    if (_single_post) {
        if(document.querySelector(__single_post+'--slider')){
            singlePostASlider(__single_post);
        }
    }

    if (_page_team) {
        if(document.querySelector(__team_arc+'--slider')){
            theTeamSlider(__team_arc);
        }
    }

    if (_page_store) {        
        if(document.querySelector(__store+'--slider')){
            storeSlider(__store);
        }
    }

    if (_homepage) {
        homeSocialSlider();
    }

    //only cases that are sensitive from ajax requests
    // $(document).ajaxStop(function () {
    //
    // });

    if(_page_product){
        const relatedProducts = new Swiper('.related-products-slider', {
            modules: [Navigation],
            slidesPerView: 2,
            spaceBetween: 5,
            loop: false,
            navigation: {
                nextEl: '.swiper-button-next-related',
                prevEl: '.swiper-button-prev-related',
            },
            breakpoints: {
                768: {
                    spaceBetween: 30,
                },
                992: {
                    spaceBetween: 60,
                },
            },
        });

        const combinedProducts = new Swiper('.combined-products-slider', {
            modules: [Navigation],
            slidesPerView: 2,
            spaceBetween: 5,
            loop: false,
            navigation: {
                nextEl: '.swiper-button-next-combined',
                prevEl: '.swiper-button-prev-combined',
            },
            breakpoints: {
                768: {
                    spaceBetween: 30,
                },
                992: {
                    spaceBetween: 60,
                },
            },
        });

        const galleryThumbs = new Swiper(".gallery-thumbnail", {
            modules: [Navigation],
            direction: "vertical",
            spaceBetween: 5,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: ".gallery-thumbnail .swiper-button-next",
                prevEl: ".gallery-thumbnail .swiper-button-prev"
            }
        });

        const galleryTop = new Swiper(".gallery-top", {
            modules: [EffectFade, Thumbs],
            spaceBetween: 10,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            thumbs: {
                swiper: galleryThumbs
            },
        });

    const slideToFirstSlide = document.querySelector("#go-to-first-element");
    const slideToVideo = document.querySelector("#go-to-video");
    if (slideToVideo){
        slideToFirstSlide.addEventListener("click", () => {
            galleryTop.slideTo(0);
        });

        slideToVideo.addEventListener("click", () => {
            galleryTop.slideTo(galleryTop.slides.length);
        });
    }


    }
});

// * CHECK IF SCREEN RESIZING HAS ENDED
var rtime;
var timeout = false;
var delta = 200;
window.addEventListener('resize', () => {
    rtime = new Date();
    if (timeout === false) {
        timeout = true;
        setTimeout(resizeend, delta);
    }
});

let resizeend = () => {
    if (new Date() - rtime < delta) {
        setTimeout(resizeend, delta);
    } else {
        timeout = false;
        // SCREEN RESIZING ENDS HERE - APPLY CHANGES BELOW
    }
};

// Add swipper classes to your swipper specified stracture markup
let selectSlider = (swContainer, swWrapper, swSlides) => {
    var swContainer = document.querySelectorAll(swContainer);
    var swWrapper = document.querySelectorAll(swWrapper);
    var swSlides = document.querySelectorAll(swSlides);

    if (swContainer) {
        swContainer.forEach(function(swC) {
            swC.classList.add('swiper');
            swWrapper.forEach(function(swW) {
                swW.classList.add('swiper-wrapper');
                for (var i = 0; i < swSlides.length; i++) {
                    swSlides[i].classList.add('swiper-slide');
                }
            })
        });
    }
};

let preStractureSlider = (sw) => {
    var swContainer = document.querySelectorAll(sw+'--slider');
    var swWrapper = document.querySelectorAll(sw+'__sliderwrap');
    var swSlides = document.querySelectorAll(sw+'__item');

    if (swContainer && swWrapper && swSlides) {
        swContainer.forEach(function(swC) {
            swC.classList.add('swiper');
            setTimeout(function(){
                swC.parentElement.classList.add('show--hidden');
           }, 2000);
            swWrapper.forEach(function(swW) {
                swW.classList.add('swiper-wrapper');
                for (var i = 0; i < swSlides.length; i++) {
                    swSlides[i].classList.add('swiper-slide');
                }
            })
        });
    }
};

let homeRecommendedSlider = (sw) => {
    preStractureSlider(sw);
    var slider = {};
    var sliderSettings = {};
    var item = document.querySelectorAll(sw+'__item');
    var itemLength = item.length;

    if (document.body.clientWidth > 991) {

        sliderSettings = {
            modules: [Navigation],
            speed: 700,
            initialSlide: 0,
            loop: true,
            centeredSlides: true,
            slidesPerView: 6.5,
            spaceBetween: 15,
            navigation: {
                prevEl: sw+'__item-prev',
                nextEl: sw+'__item-next',
            },
        };
    } else {
        sliderSettings = {
            modules: [Navigation, EffectCoverflow],
            speed: 700,
            initialSlide: 0,
            loop: itemLength >= 3,
            centeredSlides: true,
            slidesPerView: 1.6,
            effect: 'coverflow',
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                scale: 0.8,
                depth: 100,
                modifier: 1.5,
                slideShadows: false,
            },
            navigation: {
                prevEl: sw+'__item-prev',
                nextEl: sw+'__item-next',
            },
        };
    }
    slider = new Swiper(sw+'--slider', sliderSettings);

    let thisSliderWrap = document.querySelector(sw+'__sliderwrap');

    thisSliderWrap.querySelector('.swiper-slide-active').classList.add('onPoint');
    thisSliderWrap.querySelector('.swiper-slide-active .card_view').classList.add('onPoint');
    slider.on('slideChange', function () {
        // console.log('slide changed');
        thisSliderWrap.querySelector('.swiper-slide-active').classList.remove('onPoint');
        thisSliderWrap.querySelector('.swiper-slide-active .card_view').classList.remove('onPoint');
    });
    slider.on('slideChangeTransitionEnd', function () {
        // console.log('slide changed ended');
        thisSliderWrap.querySelector('.swiper-slide-active').classList.add('onPoint');
        thisSliderWrap.querySelector('.swiper-slide-active .card_view').classList.add('onPoint');
    });

}; //OK

let homeSubRecommendedSlider = (sw) => {    
    preStractureSlider(sw);
    var slider = {};
    var sliderSettings = {};
    var item = document.querySelectorAll(sw+'__item');
    var itemLength = item.length;
    
    if (document.body.clientWidth > 768) {
        sliderSettings = {
            modules: [Navigation],
            initialSlide: 0,
            loop: itemLength > 5,
            speed: 700,
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },

                992: {
                    slidesPerView: 5,
                    spaceBetween: 25,
                },
            },
            navigation: {
                prevEl: sw+'__item-prev',
                nextEl: sw+'__item-next',
            },
        };
    } else {
        sliderSettings = {
            modules: [Navigation, EffectCoverflow],
            speed: 700,
            initialSlide: 0,
            loop: itemLength >= 3,
            centeredSlides: true,
            slidesPerView: 1.7,
            effect: 'coverflow',
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                scale: 0.9,
                depth: 500,
                modifier: 1.5,
                slideShadows: false,
            },
            navigation: {
                prevEl: sw+'__item-prev',
                nextEl: sw+'__item-next',
            },
        };
    }
    slider = new Swiper(sw+'--slider', sliderSettings);
}; //OK

let homeBestSellersSlider = (sw) => {
    preStractureSlider(sw);
    var slider = {};
    var sliderSettings = {};    
    var item = document.querySelectorAll(sw+'__item');
    var itemLength = item.length;

    if (document.body.clientWidth > 768) {
        sliderSettings = {
            modules: [Navigation],
            speed: 700,
            initialSlide: 0,
            loop: itemLength > 4,
            breakpoints: {
                768: {
                    slidesPerView: 3,
                },

                992: {
                    slidesPerView: 4,
                },
            },
            navigation: {
                prevEl: sw+'__item-prev',
                nextEl: sw+'__item-next',
            },
        };
    } else {
        sliderSettings = {
            modules: [Navigation, EffectCoverflow],
            speed: 700,
            initialSlide: 0,
            loop: itemLength >= 3,
            centeredSlides: true,
            slidesPerView: 1.7,
            effect: 'coverflow',
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                scale: 0.9,
                depth: 500,
                modifier: 1.5,
                slideShadows: false,
            },
            navigation: {
                prevEl: sw+'__item-prev',
                nextEl: sw+'__item-next',
            },
        };
    }
    slider = new Swiper(sw+'--slider', sliderSettings);
}; //OK

let homeShopTheLookSlider = (sw) => {
    preStractureSlider(sw);
    var slider = {};
    var sliderSettings = {};
    var item = document.querySelectorAll(sw+'__item');
    var itemLength = item.length;

    if (document.body.clientWidth > 991) {
        sliderSettings = {
            modules: [Navigation],
            speed: 700,
            loop: false,
            initialSlide: itemLength,
            centeredSlides: true,
            slidesPerView: 9.8,
            spaceBetween: 8,
            navigation: {
                prevEl: sw+ '__item-prev',
                nextEl: sw+ '__item-next',
            },
        };
    } else {
        sliderSettings = {
            modules: [Navigation, EffectCoverflow],
            speed: 700,
            loop: false,
            initialSlide: itemLength,
            centeredSlides: true,
            slidesPerView: 1.6,
            effect: 'coverflow',
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                scale: 0.8,
                depth: 100,
                modifier: 1.5,
                slideShadows: false,
            },
            navigation: {
                prevEl: sw+ '__item-prev',
                nextEl: sw+ '__item-next',
            },
        };
    }

    slider = new Swiper(sw+'--slider', sliderSettings);

    let thisSliderWrap = document.querySelector(sw+'__sliderwrap');

    thisSliderWrap.querySelector('.swiper-slide-active .card_view').classList.add('onPoint');

    slider.on('slideChange', function () {
        // console.log('slide changed');
        thisSliderWrap.querySelector('.swiper-slide-active .card_view').classList.remove('onPoint');
        let openSelections = document.querySelector('.selection-open');
        if (openSelections) {
            openSelections.classList.remove('selection-open');
        }
    });
    slider.on('slideChangeTransitionEnd', function () {
        // console.log('slide changed ended');
        thisSliderWrap.querySelector('.swiper-slide-active .card_view').classList.add('onPoint');
    });
};

let homeSpecialOffersSlider = (sw) => {
    preStractureSlider(sw);
    var slider = {};
    var sliderSettings = {};
    var item = document.querySelectorAll(sw+'__item');
    var itemLength = item.length;

    if (document.body.clientWidth > 768) {
        sliderSettings = {
            modules: [Navigation],
            speed: 700,
            loop: itemLength >5,
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },

                992: {
                    slidesPerView: 5,
                    spaceBetween: 25,
                },
            },
            navigation: {
                prevEl: sw+ '__item-prev',
                nextEl: sw+ '__item-next',
            },
        };
    } else {
        sliderSettings = {
            modules: [Navigation, EffectCoverflow],
            speed: 700,
            loop: itemLength >= 3,
            centeredSlides: true,
            slidesPerView: 1.7,
            effect: 'coverflow',
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                scale: 0.9,
                depth: 500,
                modifier: 1.5,
                slideShadows: false,
            },
            navigation: {
                prevEl: sw+ '__item-prev',
                nextEl: sw+ '__item-next',
            },
        };
    }
    slider = new Swiper(sw+ '--slider', sliderSettings);
}; //OK

let homeSocialSlider = () => {
    var homeSocialSlider = {};
    var homeSocialSliderSettings = {
        modules: [Navigation],
        slidesPerView: 'auto',
        loop: true,
        // slidesPerView: 3,
        // spaceBetween: 80,
        navigation: {
            prevEl: '.homepage__social__item-prev',
            nextEl: '.homepage__social__item-next',
        },
    };

    homeSocialSlider = new Swiper('.homepage__social--slider', homeSocialSliderSettings);
}

let bridalBSlider = (sw) => {
    preStractureSlider(sw);
    var slider = {};
    var item = document.querySelectorAll(sw+'__item');
    var itemLength = item.length;
    if (document.body.clientWidth > 768) {
        sliderSettings = {
            modules: [Navigation],
            speed: 700,
            loop: itemLength > 5,
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 15
                },

                992: {
                    slidesPerView: 5,
                    spaceBetween: 30
                },
            },
            navigation: {
                prevEl: sw+'__item-prev',
                nextEl: sw+'__item-next',
            },
        };
    }else{
        var sliderSettings = {
            modules: [Navigation, EffectCoverflow],
            speed: 700,
            loop: true,
            centeredSlides: true,
            slidesPerView: 1.7,
            effect: 'coverflow',
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                scale: 0.9,
                depth: 500,
                modifier: 1.5,
                slideShadows: false,
            },
        };
    }


    $('.bridal-b--slider').each( function(i , obj)  {
        new Swiper(obj, sliderSettings);
    })
};

let prevCollectionASlider = (sw) => {
    preStractureSlider(sw);
    var slider = {};
    var sliderSettings = {
        modules: [Navigation],
        speed: 700,
        loop: true,
        slidesPerView: 1.3,
        breakpoints: {
            768: {
                slidesPerView: 3,
            },

            992: {
                slidesPerView: 4,
            },
        }
    };
    $('.prev-collection-a--slider').each( function(i , obj)  {
        new Swiper(obj, {...sliderSettings,
            navigation: {
                prevEl: sw+'__item-prev-'+i,
                nextEl: sw+'__item-next-'+i,
            }
        });
    })

}; //OK

let offwhiteCollectionsSlider = (sw) => {
    preStractureSlider(sw);
    var slider = {};
    var sliderSettings = {
        modules: [Navigation],
        speed: 700,
        loop: true,
        slidesPerView: 1.3,
        breakpoints: {
            768: {
                slidesPerView: 3,
            },

            992: {
                slidesPerView: 4,
            },
        }
    };
    $('.offwhite-collections__slider--slider').each( function(i , obj)  {
        new Swiper(obj, {...sliderSettings,
            navigation: {
                prevEl: sw+'__item-prev-'+i,
                nextEl: sw+'__item-next-'+i,
            }
        });
    })

}; //OK

let prevCollectionDSlider = (sw) => {
    preStractureSlider(sw);
    var slider = {};
    var sliderSettings = {};
    var item = document.querySelectorAll(sw+'__item');
    var itemLength = item.length;

    if (document.body.clientWidth > 768) {
        sliderSettings = {
            modules: [Navigation],
            speed: 700,
            loop: itemLength > 5,
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 15
                },

                992: {
                    slidesPerView: 5,
                    spaceBetween: 30
                },
            },
            navigation: {
                prevEl: sw+'__item-prev',
                nextEl: sw+'__item-next',
            },
        };
    } else {
        sliderSettings = {
            modules: [Navigation, EffectCoverflow],
            speed: 700,
            loop: itemLength >= 3,
            centeredSlides: true,
            slidesPerView: 1.7,
            effect: 'coverflow',
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                scale: 0.9,
                depth: 500,
                modifier: 1.5,
                slideShadows: false,
            },
            navigation: {
                prevEl: sw+'__item-prev',
                nextEl: sw+'__item-next',
            },
        };
    }
    slider = new Swiper(sw+'--slider', sliderSettings);
}; //OK

let singlePostASlider = (sw) => {
    preStractureSlider(sw);
    var slider = {};
    var sliderSettings = {};
    if (document.body.clientWidth > 768) {
        sliderSettings = {
            modules: [Navigation],
            speed: 700,
            loop: true,
            slidesPerView: 1.3,
            breakpoints: {
                768: {
                    slidesPerView: 3,
                },

                992: {
                    slidesPerView: 4,
                },
            },
            navigation: {
                prevEl: sw+'__item-prev',
                nextEl: sw+'__item-next',
            },
        };
    }else {
        sliderSettings = {
            modules: [Navigation, EffectCoverflow],
            speed: 700,
            loop: true,
            centeredSlides: true,
            slidesPerView: 1.7,
            effect: 'coverflow',
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                scale: 0.9,
                depth: 500,
                modifier: 1.5,
                slideShadows: false,
            }
        };
    }
    $('.single-post-a--slider').each( function(i , obj)  {
        new Swiper(obj, sliderSettings);
    })

}; //OK

let theTeamSlider = (sw) => {
    preStractureSlider(sw);
    var slider = {};
    var sliderSettings = {};

    var item = document.querySelectorAll(sw+'__item');
    var itemLength = item.length;

    if (document.body.clientWidth > 991) {

        sliderSettings = {
            modules: [Navigation],
            speed: 700,
            loop: itemLength > 5,
            allowTouchMove: false,
            slideToClickedSlide: true,
            centeredSlides: true,
            slidesPerView: 6.5,
            spaceBetween: 15,
            navigation: {
                prevEl: sw+'__item-prev',
                nextEl: sw+'__item-next',
            },
        };
    } else {
        sliderSettings = {
            modules: [Navigation, EffectCoverflow],
            speed: 700,
            loop: itemLength >= 3,
            slideToClickedSlide: true,
            centeredSlides: true,
            slidesPerView: 1.6,
            effect: 'coverflow',
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                scale: 0.8,
                depth: 100,
                modifier: 1.5,
                slideShadows: false,
            },
            navigation: {
                prevEl: sw+'__item-prev',
                nextEl: sw+'__item-next',
            },
        };
    }
    slider = new Swiper(sw+'--slider', sliderSettings);

    let thisSliderWrap = document.querySelector(sw+'__sliderwrap');

    thisSliderWrap.querySelector('.swiper-slide-active .card_view').classList.add('onPoint');
    slider.on('slideChange', function () {
        // console.log('slide changed');
        if(thisSliderWrap.querySelector('.content-open')){
            thisSliderWrap.querySelector('.content-open').classList.remove('content-open');
        }
        thisSliderWrap.querySelector('.swiper-slide-active .card_view').classList.remove('onPoint');
    });
    slider.on('slideChangeTransitionEnd', function () {
        // console.log('slide changed ended');
        thisSliderWrap.querySelector('.swiper-slide-active .card_view').classList.add('onPoint');
    });

}; //OK

let storeSlider = (sw) => {
    preStractureSlider(sw);
    var slider = {};
    var sliderSettings = {};
    var item = document.querySelectorAll(sw+'__item');
    var itemLength = item.length;

    if (document.body.clientWidth > 768) {
        sliderSettings = {
            modules: [Navigation],
            speed: 700,
            loop: itemLength > 5,
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 4,
                },

                992: {
                    slidesPerView: 4,
                    spaceBetween: 4,
                },
            },
            navigation: {
                prevEl: sw+'__item-prev',
                nextEl: sw+'__item-next',
            },
        };
    } else {
        sliderSettings = {
            modules: [Navigation, EffectCoverflow],
            speed: 700,
            loop: itemLength >= 3,
            centeredSlides: true,
            slidesPerView: 1.7,
            effect: 'coverflow',
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                scale: 0.9,
                depth: 500,
                modifier: 1.5,
                slideShadows: false,
            },
            navigation: {
                prevEl: sw+'__item-prev',
                nextEl: sw+'__item-next',
            },
        };
    }
    slider = new Swiper(sw+'--slider', sliderSettings);
}; //OK