const start = new Date();
jQuery(document).ready(function ($) {
    if (!Cookies.get('intro_video')) {
        Cookies.set('intro_video', true, {expires: 1})
        let fadeTime = 500;
        let delayTime = 6000;
        const $loader = $('#preloader').find('.loader');

        if ($loader.length) {
            $loader.addClass("animate__animated animate__fadeIn");
        }

        if ($loader.length) {
            $('#preloader').delay(delayTime).queue(function (next) {
                $loader.toggleClass("animate__fadeIn animate__fadeOut");
                next();
                $(this).fadeOut(fadeTime, function () {
                    // AOS.init({ "duration": "1000", "once": true });
                });
            });
        }
    }
});