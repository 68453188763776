$(document).ready(function () {
// Search
    $('.open-search-bar').click(function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $('.open-search-input').toggleClass('active');
        $('body').toggleClass('search-is-open');
        $('#search').removeClass('active');
    });

    $('.close-search > .close-btn-icon').click(function () {
        $('.open-search-bar').removeClass('active');
        $('.open-search-input').removeClass('active');
        $('body').removeClass('search-is-open');
    });
    $('#search input[type=text]').click(function (e) {
        e.preventDefault();
        $('#search').addClass('active');
    });

    setTimeout(function(){
        $('#search>input[name="search"]').attr('autocomplete', 'off');
    }, 500)
});