$(document).ready(function () {
    // footer
    if ($(window).width() <= 768) {
        $('.footer-wrapper > .flex-row:nth-of-type(1) > div h4').click(function () {
            if ($(this).hasClass('open')) {
                $(this).removeClass('open');
            } else {
                $('.footer-wrapper > .flex-row:nth-of-type(1) > div h4.open').removeClass('open');
                $(this).addClass('open');
            }
        });
    }


    $(window).scroll(function() {
        if($('.results-info').length){
            var hT = $('.results-info').offset().top - 150,
                hH = $('.results-info').outerHeight() - 150,
                wH = $(window).height(),
                wS = $(this).scrollTop();

            if (wS > (hT+hH-wH) && (hT > wS) && (wS+wH > hT+hH) && $.active === 0){
                $('.load-more a').trigger('click');
            }
        }

    });

    $('#product-category, #product-search, #product-manufacturer').on('click','.load-more a', function () {
        var url = $(this).attr('href');
        if (typeof xhr !== 'undefined') {
            xhr.abort()
        }
        xhr = $.ajax({
            url: url + '&loadmore=1',
            type: 'get',
            data: {"countVideo": $("#countVideo").val(), "videoPosition": $('#videoPosition').val()},
            dataType: 'json',
            beforeSend: function() {
               // $('body').append('<span class="basel-spinner ajax-call"></span>');
                $('.grid-holder.product-holder').fadeTo( 0, 0.4 );
            },
            success: function(json) {
                $('.product-holder').append(json.products);
                $('.category-pagination .pagination-div').html(json.pagination);
                $('.results-info').html(json.results_info);
                $('#videoPosition').val(json.video_position);
                $('#countVideo').val(json.countVideos);
                if (typeof BrainyFilter !== 'undefined') {
                    BrainyFilter.addBFilterParam();
                    if (url.indexOf('page=') !== -1) {
                        BrainyFilter.historyPushState({}, url);
                    }
                } else {
                    window.history.pushState({brainyfilter: true}, document.title, url);
                }
               // $('.basel-spinner.ajax-call').remove();
                $('.category-listing__item').css('opacity' , 1);
                $('.products-block.product-holder').fadeTo( 400, 1 );
            }
        });
        return false;
    })

});
