let forceInputUppercase = (e) => {
    var start = e.target.selectionStart;
    var end = e.target.selectionEnd;
    e.target.value = e.target.value.toUpperCase();
    e.target.setSelectionRange(start, end);
}

let onlyLatinCharacters = (str) => {
    // return /^[a-z]+$/i.test(str);
    return /^[0-9a-zA-Z\s.,@]+$/.test(str);
}

let onlyLatinCharactersEmail = (str) => {
    // return /^[a-z]+$/i.test(str);
    return /^[0-9a-zA-Z\s.\-@_]+$/.test(str);
}

let inputCustomValidation = (inputTarget, type) => {
    if (inputTarget && type == 'query') {
        // alert('query');

        inputTarget.forEach((input) => {
            input.addEventListener('keyup', forceInputUppercase, false);
            input.addEventListener('keyup', function() {
                let insertedText = input.value;
                // console.log(insertedText);

                if (onlyLatinCharacters(insertedText) == false) {
                    input.value = '';
                    custom_error_msg.textContent = 'Only -LATIN- characters, numbers & -.,@- are accepted.';
                    input.parentElement.append(custom_error_msg);
                }

                if(input.parentElement.querySelector('.not-latin')) {
                    // console.log('exists');

                    if (onlyLatinCharacters(insertedText)) {
                        input.parentElement.querySelector('.not-latin').remove();
                    }
                }

            });
        });

    }

    if (inputTarget && type == 'mail') {
        // alert('mail');

        inputTarget.forEach((input) => {
            input.addEventListener('keyup', function() {
                let insertedText = input.value;
                // console.log(insertedText);

                if (onlyLatinCharactersEmail(insertedText) == false) {
                    input.value = '';
                    custom_error_msg.textContent = 'Only -LATIN- characters, numbers & .-@ are accepted.';
                    input.parentElement.append(custom_error_msg);
                }

                if(input.parentElement.querySelector('.not-latin')) {
                    // console.log('exists');

                    if (onlyLatinCharactersEmail(insertedText)) {
                        input.parentElement.querySelector('.not-latin').remove();
                    }
                }

            });
        });

    }

    if (inputTarget && type == 'tag') {        
        // alert('tag');

        setTimeout(function() { 
            
            for (var i = 0; i < inputTarget.length; i++) {
                inputTarget[i].addEventListener('keyup', forceInputUppercase, false);
                inputTarget[i].addEventListener('keyup', function() {
                    let insertedText = this.value;
                    // console.log(insertedText);

                    if (onlyLatinCharacters(insertedText) == false) {
                        this.value = '';
                        custom_error_msg.textContent = 'Only -LATIN- characters are accepted.';
                        this.parentElement.append(custom_error_msg);
                    }

                    if(this.parentElement.querySelector('.not-latin')) {
                        // console.log('exists');

                        if (onlyLatinCharacters(insertedText)) {
                            this.parentElement.querySelector('.not-latin').remove();
                        }
                    }
                });
            }
            
        }, 1000);

    }

}

let custom_error_msg = document.createElement('div');
custom_error_msg.classList.add('js-has-error', 'not-latin');

const input_target_text = document.querySelectorAll('input[type=text]:not([name=birthday], [name=telephone], [name=to_email], [name=from_email], [name=email])');
const input_target_email = document.querySelectorAll('input[type=email], input[name=email]');
const input_target_postcode = document.querySelectorAll('input[name=postcode]');
const input_target_textarea = document.getElementsByTagName('textarea:not([name=message]');

window.addEventListener('DOMContentLoaded', () => {
    inputCustomValidation(input_target_text, 'query');
    inputCustomValidation(input_target_email, 'mail');
    inputCustomValidation(input_target_postcode, 'mail');
    inputCustomValidation(input_target_textarea, 'tag');
});