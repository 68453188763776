const tabs = document.querySelectorAll('.tab__header')
const tabBody = document.querySelectorAll('.tab__body')

tabs.forEach(tab => {
    tab.addEventListener("click", function(event) {
        event.preventDefault();
        const targetTab = tab.getAttribute("href");
        tabBody.forEach(tabContent => {
            tabContent.classList.remove('active')
        })
        tabs.forEach(tab => {
            tab.classList.remove('active')
        })
        tab.classList.add('active')
        document.querySelector(targetTab).classList.add('active')
    })
})
