const showAllAmenities = document.querySelector('#see_all_amenities')
const allAmenities = document.querySelectorAll('.more__amenities')

if (showAllAmenities) {
    showAllAmenities.addEventListener('click', function(event) {
        event.preventDefault()
        allAmenities.forEach(item => {
            if(item.classList.contains("hidden__amenities")){
                item.classList.remove('hidden__amenities')
                showAllAmenities.innerHTML = "Show less amenities"
            } else {
                item.classList.add('hidden__amenities')
                showAllAmenities.innerHTML = "See all amenities"
            }
        })
    });
}