let screenHeight;
let screenWidth;
const siteBody = document.body;
const siteHeader = document.querySelector('header');
const filters = document.querySelector('.filters');
const stickyTitle = document.querySelector('.sticky--title');
const headerUpper = document.querySelector('.header--upper');
const headerLogo = document.querySelector('.header--logo');

window.addEventListener('DOMContentLoaded', () => {
    screenHeight = window.innerHeight;
    screenWidth = window.innerWidth;
    cssRootVariable(siteBody, screenHeight, '--screen-height');
    cssRootVariable(siteBody, screenWidth, '--screen-width');
    cssRootVariable(siteBody, siteHeader, '--header-height');
    cssRootVariable(siteBody, filters, '--filters-height');
    cssRootVariable(siteBody, stickyTitle, '--sticky-title-height');
    cssRootVariable(siteBody, headerUpper, '--header--upper');
    cssRootVariable(siteBody, headerLogo, '--header--logo');
    hashScroller('.scroll-to-hash');
    if (screenWidth >= 992) {
        hashScroller('.scroll-to-hash-extra', '.sticky--title');
    } else {
        hashScroller('.scroll-to-hash-extra', '.property__sticky--section.second');
    }
    hoverClassToggler('.plan__sliderwrap', '.plan__item', 'plan__item--active');
    let js_show = document.querySelectorAll('.js-show');
    let js_show_slow = document.querySelectorAll('.js-show-slow');
    js_show.forEach((js_show_item) => {
        js_show_item.style.opacity = "1";
    });
    setTimeout(function(){
        js_show_slow.forEach((js_show_slow_item) => {
            js_show_slow_item.style.opacity = "1";
        });
    }, 2000);

});

// * CHECK IF SCREEN RESIZING HAS ENDED
var rtime;
var timeout = false;
var delta = 200;
window.addEventListener('resize', () => {
    cssRootVariable(siteBody, siteHeader, '--header-height');
    cssRootVariable(siteBody, filters, '--filters-height');
    cssRootVariable(siteBody, stickyTitle, '--sticky-title-height');
    rtime = new Date();
    if (timeout === false) {
        timeout = true;
        setTimeout(resizeend, delta);
    }
});

let resizeend = () => {
    if (new Date() - rtime < delta) {
        setTimeout(resizeend, delta);
    } else {
        timeout = false;
        // SCREEN RESIZING ENDS HERE - APPLY CHANGES BELOW
        screenHeight = window.innerHeight;
        screenWidth = window.innerWidth;
        cssRootVariable(siteBody, screenHeight, '--screen-height');
        cssRootVariable(siteBody, screenWidth, '--screen-width');
    }
};

// Functions
let cssRootVariable = (scope, target, propertyName) => {
    if (target) {
        var value;
        if (typeof target === 'number') {
            value = target;
        } else {
            value = target.offsetHeight;
        }
        scope.style.setProperty(propertyName, `${value}px`);
    }
};

// toggle class with reset to default state
let hoverClassToggler = (parent, target, toggleClass) => {
    var defaultSelectedElement = 1;
    var wrapEl = document.querySelector(parent);
    var el = document.querySelectorAll(target);

    if (wrapEl) {
        for (var i = 0; i < el.length; i++) {
            el[i].onmouseenter = function () {
                for (var j = 0; j < el.length; j++) {
                    el[j].classList.remove(toggleClass);
                }
                this.classList.add(toggleClass);
            };
            el[i].onmouseleave = function () {
                this.classList.remove(toggleClass);
            };
        }
        wrapEl.onmouseleave = function () {
            el[defaultSelectedElement - 1].classList.add(toggleClass);
        };
    }
};

let hashScroller = (cl, xh) => {
    let scrollers = document.querySelectorAll(cl);
    scrollers.forEach((scroller) => {
        scroller.addEventListener('click', function (e) {
            e.preventDefault();
            headerHeight = document.querySelector('header').offsetHeight;
            $target = document.querySelector(this.href.substring(this.href.lastIndexOf('/') + 1));
            if (xh) {
                if (screenWidth >= 992) {
                    extraHeight = document.querySelector(xh).offsetHeight;
                    var hh = $target.getBoundingClientRect().top + window.scrollY - headerHeight - extraHeight - 55;
                } else {
                    extraHeight = document.querySelector(xh).offsetHeight;
                    var hh = $target.getBoundingClientRect().top + window.scrollY - headerHeight - extraHeight;
                }
            } else {
                var hh = $target.getBoundingClientRect().top + window.scrollY - headerHeight;
            }

            window.scrollTo({
                top: hh,
                behavior: 'smooth',
            });
        });
    });
};

window.isTouchDevice = function () {
    return true == ('ontouchstart' in window || window.DocumentTouch && document instanceof DocumentTouch);
}

window.current = function () {
    var state = 'click';
    if (isTouchDevice() === true) {
        state = 'touchstart';
    }
    return {
        h: jQuery(window).height(),
        w: jQuery(window).width(),
        scroll: jQuery(window).scrollTop(),
        state: state
    };
}

if (current().w <= 1024 && current().state == 'touchstart') {
    // PRE TOUCH TARGET
    let pre_touch  = document.querySelectorAll('.js-pre-touch ');

    // LISTING ITEMS
    let category_listing_items  = document.querySelectorAll('.category-listing__item');

    // LISTING ITEMS
    if(category_listing_items) {
        category_listing_items.forEach(item => {
            item.classList.add('js-pre-touch');

            item.addEventListener('click', function (e) {
                let itemSiblings = Array.prototype.slice.call(category_listing_items[0].parentElement.children);
                itemSiblings.forEach(sibling => {
                    sibling.classList.add('js-pre-touch');
                });
                item.classList.remove('js-pre-touch');
            });
        });
    }

    // ALL POSSIBLE SLIDERS
    let touch_controlled_sliders  = document.querySelectorAll('.js-pre-touch-control');

    if(touch_controlled_sliders) {
        // FOR EACH SLIDER
        touch_controlled_sliders.forEach(slider => {
            
            let allSliderItems = Array.from(slider.children);
            allSliderItems.forEach(item => {
                let cardView = item.querySelector('.card_view');

                if(cardView) {
                    cardView.classList.add('js-pre-touch');
                    item.addEventListener('click', function (e) {
                        let cardViewSiblings = Array.prototype.slice.call(allSliderItems[0].parentElement.children);
                        cardViewSiblings.forEach(sibling => {
                            sibling.querySelector('.card_view').classList.add('js-pre-touch');
                        });
                        cardView.classList.remove('js-pre-touch');                    
                    });
                }

            });
        });
    }

    // LISTING ITEMS & SLIDER ITEMS
    if(pre_touch) {
        pre_touch.forEach(item => {
            item.addEventListener('click', function (e) {
                e.preventDefault();
            });
        });
    }
}

window.toggleSound = function (id, handler) {
    var video = document.getElementById(id);
    if (video.muted === true) {
        // console.log('MUTED FALSE');
        video.muted = false;
    } else if (video.muted === false) {
        // console.log('MUTED TRUE');
        video.muted = true;
    }
};

// window.addEventListener('DOMContentLoaded', () => {
//     document.addEventListener('keydown', function (e) {
//         e = e || window.event;
//         e.keyCode;
//         if (e.keyCode == 27) {
//             enquireForm.forEach(formEl => {
//                 formEl.classList.remove('active');
//             })
//             pageOverlay.classList.remove('active');
//         }
//     });
// });




