const giftCardPrice = document.getElementById('input-amount');
const giftThumbs = document.getElementsByClassName('gift-thumb');
const mainImageSlider = document.querySelector("#gift-zoom");
const image50 = document.getElementById("img_card_50");
const image100 = document.getElementById("img_card_100");
const image150 = document.getElementById("img_card_150");
const image200 = document.getElementById("img_card_200");
const zoomImg = document.getElementById("gift-zoom-img");

if (giftCardPrice) {
    giftCardPrice.addEventListener('change', function handleChange(event) {
        const amount = event.target.value
        document.getElementById("gift-card-price").innerHTML = amount;
        if (amount == 50) {
            const element = document.querySelector('#gift_card_50');
            for(giftThumb of giftThumbs){
                giftThumb.classList.remove('mz-thumb-selected')
            }
            element.classList.add( 'mz-thumb-selected');
            zoomImg.src = image50.src;
        } else if(amount == 100) {
            const element = document.querySelector('#gift_card_100');
            for(giftThumb of giftThumbs){
                giftThumb.classList.remove('mz-thumb-selected')
            }
            element.classList.add('mz-thumb-selected');
            zoomImg.src = image100.src;
        } else if(amount == 150) {
            const element = document.querySelector('#gift_card_150');
            for(giftThumb of giftThumbs){
                giftThumb.classList.remove('mz-thumb-selected')
            }
            element.classList.add('mz-thumb-selected');
            zoomImg.src = image150.src;
        }else{
            const element = document.querySelector('#gift_card_200');
            for(giftThumb of giftThumbs){
                giftThumb.classList.remove('mz-thumb-selected')
            }
            element.classList.add('mz-thumb-selected');
            zoomImg.src = image200.src;
        }
    });
}

if (image50) {
    image50.addEventListener('click', function () {
        document.getElementById("amount-50").checked = true;
    });
}

if (image100) {
    image100.addEventListener('click', function () {
        document.getElementById("amount-100").checked = true;
    });
}

if (image150) {
    image150.addEventListener('click', function () {
        document.getElementById("amount-150").checked = true;
    });
}

if (image200) {
    image200.addEventListener('click', function () {
        document.getElementById("amount-200").checked = true;
    });
}
