let screenWidth = window.innerWidth;
const megamenuBackgroundColor = document.querySelector('.megamenu');
const headerLogo = document.querySelector('.header--logo');
const categoryColors = document.getElementsByClassName('with-sub-menu');
const logoIcon = document.getElementsByClassName('logo');
const burgerMenu = document.getElementsByClassName('navbar-toggle');
const megaMenu = document.getElementsByClassName('megamenu');

for (const categoryColor of categoryColors) {
    categoryColor.addEventListener("mouseover", (event) => {
        categoryColor.classList.add("colorize_header");
        const currentColor = categoryColor.dataset.color;

        const hasWhiteTextAndLogo = categoryColor.dataset.logocolor

        const bodyColor = document.getElementsByTagName("body");

        if(hasWhiteTextAndLogo == 1){
            logoIcon[0].classList.add('white-logo')
            megaMenu[0].classList.add('white-menu')
            burgerMenu[0].classList.add('white-burger')
        } else {
            logoIcon[0].classList.remove('white-logo')
            megaMenu[0].classList.remove('white-menu')
            burgerMenu[0].classList.remove('white-burger')
        }
        const submenuBackgroundColor = categoryColor.querySelector('.sub-menu');
        categoryColor.style.backgroundColor = "inherit";

        megamenuBackgroundColor.style.backgroundColor = currentColor;
        headerLogo.style.backgroundColor = currentColor;
        submenuBackgroundColor.style.backgroundColor = currentColor;
    });


    categoryColor.addEventListener("mouseleave", (event) => {

        logoIcon[0].classList.remove('white-logo')
        megaMenu[0].classList.remove('white-menu')
        burgerMenu[0].classList.remove('white-burger')
        megamenuBackgroundColor.style.backgroundColor = '';
        headerLogo.style.backgroundColor = '';
        const submenuBackgroundColor = categoryColor.querySelector('.sub-menu');
        submenuBackgroundColor.style.backgroundColor = '';

    });
}
$(document).ready(function () {
    if ($(window).width() <= 1200) {
        $('.title-submenu').on('click', function (e) {
            e.preventDefault();
            if (!$(this).parent().hasClass('_current')) {
                $('.subcategory .title-submenu').each(function (index, el) {
                    $(el).parent().removeClass('_open _current');
                });
            }
            $(this).parent().toggleClass('_open');
            $(this).parent().addClass('_current');
        });

        $('.subcategory.mobile-view-all-category').each(function (index, el) {
            let categoryMenu = $(el).find('.hover-menu .menu ul');
            let categoryLink = $(el).find('.title-submenu.mobile-view-all-category');
            let categoryLinkUrl = categoryLink.attr('href');

            $("<a/>", {
                "class": "viewAll",
                text: 'View All',
                "title": 'View All',
                "href": categoryLinkUrl
            }).prependTo(categoryMenu);


        });
    }
});


