import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
gsap.registerPlugin(ScrollTrigger);
import { DrawSVGPlugin } from './DrawSVGPlugin.js';
gsap.registerPlugin(DrawSVGPlugin);

window.addEventListener('DOMContentLoaded', () => {
    selfFadeEffect('.fade-in');
    // selfFadeEffectDelayed('.fade-in-delayed-1', 1);
    // selfFadeEffectDelayed('.fade-in-delayed-2', .25);
    // selfFadeEffectDelayed('.fade-in-delayed-3', .5);
    childrenFadeEffect('.fade-children');
    // childrenFadeEffectDelayed('.fade-children-delayed-1', 1);
    // childrenFadeEffectDelayed('.fade-children-delayed-2', 2);
    

    // imageBloatAnim('.anim-image-home');
    // imageBloatScrollAnim('.anim-image-services-1');
    // imageBloatScrollAnim('.anim-image-services-2');
    // imageBloatScrollAnim('.anim-image-services-3');
    // imageBloatScrollAnim('.anim-image-services-4');
    // imageBloatScrollAnim('.anim-image-services-5');

    // letterAnim('.property-management__content strong');
});

// * CHECK IF SCREEN RESIZING HAS ENDED
var rtime;
var timeout = false;
var delta = 200;
window.addEventListener('resize', () => {
    rtime = new Date();
    if (timeout === false) {
        timeout = true;
        setTimeout(resizeend, delta);
    }
});

let resizeend = () => {
    if (new Date() - rtime < delta) {
        setTimeout(resizeend, delta);
    } else {
        timeout = false;
        // SCREEN RESIZING ENDS HERE - APPLY CHANGES BELOW
    }
};

let selfFadeEffect = (target) => {
    // fade in animation for each defined element
    const fadeIn = document.querySelectorAll(target);
    if (fadeIn.length) {
        fadeIn.forEach((el) => {
            const fadeTl = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: 'top 90%',
                },
            });
            fadeTl.fromTo(el, { yPercent: 5, opacity: 0 }, { yPercent: 0, opacity: 1, duration: 1, ease: 'ease' });
        });
    }
};

let selfFadeEffectDelayed = (target, delay) => {
    // fade in animation for each defined element
    const fadeIn = document.querySelectorAll(target);
    if (fadeIn.length) {
        fadeIn.forEach((el) => {
            const fadeTl = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: 'top 90%',
                },
            });
            fadeTl.fromTo(el, { yPercent: 5, opacity: 0 }, { yPercent: 0, opacity: 1, duration: 1, delay: delay, ease: 'ease' });
        });
    }
};

let childrenFadeEffect = (target) => {
    // fade in animation for defined element's children
    const fadeChildren = document.querySelectorAll(target);
    if (fadeChildren.length) {
        fadeChildren.forEach((el) => {
            const showTl = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: 'top 90%',
                },
            });
            showTl.fromTo(el.children, { y: 20, opacity: 0 }, { y: 0, opacity: 1, duration: 1, stagger: 0.5, ease: 'ease' });
        });
    }
};

let childrenFadeEffectDelayed = (target, delay) => {
    // fade in animation for defined element's children
    const fadeChildren = document.querySelectorAll(target);
    if (fadeChildren.length) {
        fadeChildren.forEach((el) => {
            const showTl = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: 'top 90%',
                },
            });
            showTl.fromTo(el.children, { y: 20, opacity: 0 }, { y: 0, opacity: 1, duration: 1, delay: delay, stagger: 0.5, ease: 'ease' });
        });
    }
};

// IMAGE BLOAT ANIMATION
let imageBloatAnim = (el) => {
    var image1TlFrom, image1TlTo;
    const imageWrap = document.querySelector(el);
    const image = document.querySelector(el + '>img');
    var bloat = getComputedStyle(document.body);
    
    image1TlFrom = {
        paddingTop: bloat.getPropertyValue('--bloat'),
        paddingLeft: bloat.getPropertyValue('--bloat'),
        paddingRight: bloat.getPropertyValue('--bloat'),
        marginTop: 83,
    };

    image1TlTo = {
        paddingTop: '0%',
        paddingLeft: '0%',
        paddingRight: '0%',
        marginTop: 0,
    };

    image1TlTo.duration = 1;
    image1TlTo.ease = 'none';

    if (imageWrap) {
        let image1Tl = gsap.timeline();
        image1Tl.fromTo(image, image1TlFrom, image1TlTo);
    }
};

// IMAGE BLOAT ANIMATION ON SCROLL
let imageBloatScrollAnim = (el) => {
    var image1TlFrom, image1TlTo;
    const imageWrap = document.querySelector(el);
    const image = document.querySelector(el + '>img');
    var bloat = getComputedStyle(document.body);

    image1TlFrom = {
        paddingTop: bloat.getPropertyValue('--bloat'),
        paddingLeft: bloat.getPropertyValue('--bloat'),
        paddingRight: bloat.getPropertyValue('--bloat'),
        marginTop: 83,
    };

    image1TlTo = {
        paddingTop: '0%',
        paddingLeft: '0%',
        paddingRight: '0%',
        marginTop: 0,
    };

    image1TlTo.duration = 0.75;
    image1TlTo.ease = 'none';

    if (imageWrap) {
        let image1Tl = gsap.timeline({
            scrollTrigger: {
                trigger: el,
                markers: false,
                start: 'top 100%',
                // start: 'top 60%',
                end: '+=1%',
                // scrub: 1,
            },
        });
        image1Tl.fromTo(image, image1TlFrom, image1TlTo);
    }
};

function goToSection(element) {
    ScrollTrigger.create({
        trigger: '#' + element,
        start: 'top 40%',
        end: 'bottom 40%',
        markers: false,
        scrub: false,
        // invalidateOnRefresh: true,
        onEnter: function () {
            jQuery('.property__nav--item').removeClass('active');
            jQuery('.' + element).addClass('active');
        },
        onEnterBack: function () {
            jQuery('.property__nav--item').removeClass('active');
            jQuery('.' + element).addClass('active');
        },
    });
}

// LETTER ANIMATION
let letterAnim = (el) => {
    let letterTlFrom, letterTlTo;
    const targets = document.querySelectorAll(el);

    letterTlFrom = {};
    letterTlTo = {
        textShadow: '-1px -1px 0 transparent, 1px -1px 0 transparent, -1px 1px 0 transparent, 1px 1px 0 transparent',
        color: '#4B92C1',
    };

    letterTlTo.duration = 0.5;
    letterTlTo.stagger = 0.05;
    letterTlTo.ease = 'none';

    if (targets.length) {
        targets.forEach((target, i) => {
            let letterTl = [];
            letterTl[i] = gsap.timeline({
                scrollTrigger: {
                    trigger: target,
                    // markers: true,
                    start: 'top 75%',
                    // start: 'top 60%',
                    end: '+=10%',
                    // scrub: 1,
                },
            });
            letterTl[i].fromTo(target.children, letterTlFrom, letterTlTo);
        });
    }
};