window.addEventListener('DOMContentLoaded', () => {
    let screenWidth = window.innerWidth;
    const handleMiniCart = document.querySelector('.cart-btn');
    const miniCartBody = document.querySelector('.minicart-body');
    const closeMiniCart = document.querySelector('.close-mini-cart-btn');
    const navBarToggle = document.querySelector('.navbar-toggle');
    const megaMenuWrapper = document.querySelector('.megamenu-wrapper');
    const pageOverlay = document.querySelector('.body-cover');
    const body = document.getElementsByTagName('body')[0];
    //
    const handleSizeGuide = document.querySelector('.trigger-size-guide-popup');
    const sizeGuidePopup = document.querySelector('.size-guide-popup');
    const closeSizeGuide = document.querySelector('.close-size-guide-btn');
    //
    const sizeGuideModalTriggerBridal = document.querySelector('.js-size-guide-modal-trigger-bridal');
    const sizeGuideModalBridal = document.querySelector('.js-size-guide-modal-bridal');
    const sizeGuideCloseBridal = document.querySelector('.js-size-guide-close-bridal');

    if (handleMiniCart) {
        handleMiniCart.addEventListener('click', function handleClick(event) {
            body.classList.toggle('cart-is-open');
            body.classList.remove('menu-is-open');
            miniCartBody.classList.toggle('open');
            navBarToggle.classList.remove('active');
            megaMenuWrapper.classList.remove('so-megamenu-active');
        });
    }

    if (closeMiniCart) {
        closeMiniCart.addEventListener('click', function handleClick(event) {
            event.preventDefault();
            miniCartBody.classList.remove('open');
            body.classList.remove('cart-is-open');
            body.classList.remove('menu-is-open');
        });
    }

    if (closeSizeGuide) {
        closeSizeGuide.addEventListener('click', function handleClick(event) {
            event.preventDefault();
            sizeGuidePopup.classList.remove('active');
            body.classList.remove('size-guide-is-open');
        });
    }

    if (sizeGuideCloseBridal) {
        sizeGuideCloseBridal.addEventListener('click', function handleClick(event) {
            event.preventDefault();
            sizeGuideModalBridal.classList.remove('active');
            body.classList.remove('size-guide-is-open');
        });
    }

    if (handleSizeGuide) {
        handleSizeGuide.addEventListener('click', function handleClick(event) {
            event.preventDefault();
            body.classList.remove('cart-is-open');
            body.classList.remove('menu-is-open');
            body.classList.toggle('size-guide-is-open');
            if (sizeGuidePopup){
                sizeGuidePopup.classList.toggle('active');
            }
        });
    }

    if (sizeGuideModalTriggerBridal) {
        sizeGuideModalTriggerBridal.addEventListener('click', function handleClick(event) {
            event.preventDefault();
            body.classList.remove('cart-is-open');
            body.classList.remove('menu-is-open');
            body.classList.toggle('size-guide-is-open');
            if (sizeGuideModalBridal){
                sizeGuideModalBridal.classList.toggle('active');
            }
        });
    }

    if (pageOverlay) {
        pageOverlay.addEventListener('click', function handleClick(event) {
            body.classList.remove('cart-is-open');
            body.classList.remove('menu-is-open');
            body.classList.remove('size-guide-is-open');
            miniCartBody.classList.remove('open');
            megaMenuWrapper.classList.remove('so-megamenu-active');
            navBarToggle.classList.remove('active');
            sizeGuidePopup.classList.remove('active');
            sizeGuideModalBridal.classList.remove('active');
        });
    }

    if (navBarToggle) {
        navBarToggle.addEventListener('click', function handleClick(event) {
            body.classList.remove('cart-is-open');
            miniCartBody.classList.remove('open');
            if(screenWidth <= 1200) {
                let theSelector = $(this).parent();
                if (theSelector.hasClass('active-show')) {
                    theSelector.removeClass('active-show');
                    theSelector.removeClass('click');
                }
                else {
                    $('ul.megamenu > li.with-sub-menu').removeClass('active-show');
                    theSelector.addClass('active-show');
                    theSelector.addClass('click');
                }
            }
        });
    }

});