import './menu';
import './scripts';
import './tabs';
import './show_more';
import './fancybox';
import './footer';
import './gift_card';
import './loader';
import './search';
import './select2-customize';
import './legal';